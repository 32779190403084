import {Badge, Button} from "@chakra-ui/react";
import {Link} from "react-router-dom";
import React from "react";

const NavBar = ({children, ...props}: any) => (
    <>
        <Button variant="ghost"><Link to="/">Acasa</Link></Button>
        <Button variant="ghost">
            <Link to="/organizatii">Organizatii</Link>
            <Badge variant='subtle' ml={2} colorScheme='red'>
                NOU
            </Badge>
        </Button>
        <Button variant="ghost"><Link to="/despre">Despre proiect</Link></Button>
        <Button variant="ghost"><Link to="/statistici">Statistici</Link></Button>
        <Button variant="ghost"><Link to="/intrebari">Intrebari frecvente</Link></Button>
    </>
);

export default NavBar;
