import {createResource, Entity, RestEndpoint} from '@rest-hooks/rest';
import configs from '../config/config';

export class Authority extends Entity {
    id = 0;
    address = '';
    email = '';
    name = '';
    type = 0;

    pk() {
        return `${this.id}`;
    }
}