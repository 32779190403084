import NavBar from "./NavBar";
import {Link, Outlet, Routes} from "react-router-dom";
import {
    Button,
    Center, Container, Flex, Image,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text, useDisclosure
} from "@chakra-ui/react";
import LogoAsociatie from "../assets/logo-gal-berceni.png";
import React from "react";

const Layout = () => {
    const {isOpen: aboutUsOpen, onOpen: openAboutUs, onClose: closeAboutUs} = useDisclosure();

    return (
        <>
            <NavBar/>
            <Container maxW='container.lg'>
                <Outlet />
                <Flex width="100%" justifyContent="flex-end" mt={2} cursor="pointer" gap={4} fontSize="xs">
                    <Link to="/confidentialitate">Politica de confidentialitate</Link> | <Link to="/cookies">Politia de fisiere Cookies</Link>
                </Flex>
            </Container>
        </>
    );
}

export default Layout;