import React, {useEffect, useState} from "react";
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    ScatterChart,
    Scatter,
    LineChart,
    Legend,
    Line,
    BarChart,
    ReferenceLine,
    Bar,
    Rectangle,
} from "recharts";
import {useSuspense} from "@rest-hooks/react";
import {reportData} from "../api/Sensor";
import {Box} from "@chakra-ui/react";
import _ from "lodash";
import PieChartLocal from "../components/PieChart";

export default function Stats() {

    const stats = useSuspense(reportData);
    const [offset, setOffset] = useState<number>(0);

    useEffect(() => {
        const max: {gas1: number} | undefined = _.maxBy(stats.sensorDataH2s, function (o) { return o.gas1; });
        if (typeof  max !== "undefined") {
            setOffset(max.gas1 - 0.008);
        }
    }, [stats]);
    return (
        <>
            <Box boxShadow="md" bg="gray.100" rounded={4} p={4}>
                <div style={{width: '100%', height: 300}}>
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                            width={500}
                            height={300}
                            data={stats.reportsPerCity}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="city" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="nr" fill="#8884d8" activeBar={<Rectangle fill="pink" stroke="blue" />} />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </Box>
            <Box boxShadow="md" bg="gray.100" rounded={4} p={4}>
                <div style={{width: '100%', height: 300}}>
                    <ResponsiveContainer>
                        <AreaChart
                            width={900}
                            height={400}
                            data={stats.sensorDataH2s}
                            margin={{
                                top: 10,
                                right: 30,
                                left: 0,
                                bottom: 0,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3"/>
                            <XAxis dataKey="timestamp"/>
                            <YAxis dataKey="gas1"/>
                            <Tooltip/>
                            <defs>
                                <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset={0} stopColor="red" stopOpacity={1}/>
                                    <stop offset={1} stopColor="green" stopOpacity={1}/>
                                </linearGradient>
                            </defs>
                            <Area
                                type="monotone"
                                dataKey="gas1"
                                stroke="#000"
                                fill="url(#splitColor)"
                                baseValue={0.008}
                            />
                        </AreaChart>
                    </ResponsiveContainer>
                </div>
            </Box>
            <Box boxShadow="md" bg="gray.100" rounded={4} p={4}>
                <div style={{width: '100%', height: 300}}>
                    <ResponsiveContainer>
                        <AreaChart
                            width={500}
                            height={400}
                            data={stats.reportsPerDay}
                            margin={{
                                top: 10,
                                right: 30,
                                left: 0,
                                bottom: 0
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3"/>
                            <XAxis dataKey="day"/>
                            <YAxis/>
                            <Tooltip/>
                            <Area type="monotone" dataKey="nr" stroke="#8884d8" fill="#8884d8"/>
                        </AreaChart>
                    </ResponsiveContainer>
                </div>
            </Box>
            <Box boxShadow="md" bg="gray.100" rounded={4} p={4}>
                <div style={{width: '100%', height: 300}}>
                    <ResponsiveContainer>
                        <AreaChart
                            width={500}
                            height={400}
                            data={stats.reportsPerHours}
                            margin={{
                                top: 10,
                                right: 30,
                                left: 0,
                                bottom: 0
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3"/>
                            <XAxis dataKey="hour"/>
                            <YAxis/>
                            <Tooltip/>
                            <Area type="monotone" dataKey="nr" stroke="#8884d8" fill="#8884d8"/>
                        </AreaChart>
                    </ResponsiveContainer>
                </div>
            </Box>
        </>
);
}
