import {Entity, RestEndpoint} from "@rest-hooks/rest";
import configs from "../config/config";
import {News} from "./News";

export class Sensor extends Entity {
    id = 0;
    time = 0;
    latitude = 0;
    longitude = 0;
    altitude = 0;
    speed = 0;
    gas1 = 0;
    perc = 0;
    now = 0;

    pk() {
        return `${this.gas1}`;
    }
}

export const sensorData = new RestEndpoint({
    urlPrefix: configs.apiUrl,
    path: '/stats/sensor/:id',
    method: 'GET',
    schema: Sensor,
});

export const sensorsGet = new RestEndpoint({
    urlPrefix: configs.apiUrl,
    path: '/stats/sensors/:id',
    method: 'GET',
});

export const reportData = new RestEndpoint({
    urlPrefix: configs.apiUrl,
    path: '/stats/data',
    method: 'GET',
});
