import {Entity, RestEndpoint} from "@rest-hooks/rest";
import configs from "../config/config";

export class Wind extends Entity {
    id = 0;
    direction = '';
    degrees = null;
    speed = null;
    createdAt:string = '';

    pk() {
        return `${this.id}`;
    }
}

export const windList = new RestEndpoint({
    urlPrefix: configs.apiUrl,
    path: '/wind',
    method: 'GET',
    schema: Wind,
});