import {Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Heading} from "@chakra-ui/react";

interface QA{
    question: string;
    answer: string;
}

const qaItems: QA[] = [
    {
        question: "Ce este IarMiroase.ro?",
        answer: "IarMiroase.Ro este o aplicatie web care doreste sa stranga in acelasi loc oameni care au o problema comuna si anume mirosul de gunoi din sudul zonei Bucuresti-Ilfov."
    },
    {
        question: "Cui se adreseaza aceasta aplicatie",
        answer: "Aplicatia se adreseaza tuturor celor care locuiesc langa o sursa de disconfort olfactiv (miros)"
    },
    {
        question: "Cine a dezvoltat acest proiect?",
        answer: "Din punct de vedere legal proiectul a luat fiinta la initiativa si cu suportul Asociatiei Grup de Actiune Locala Berceni. Din punct de vedere moral proiectul este al tuturor, al cetatenilor care se confrunta cu aceasta problema. "
    }
    ,{
        question: "Care sunt avantajele aduse de aplicatie?",
        answer: "In primul rand strange in acelasi loc oameni cu aceeasi problema si creeaza o comunitate unita. O comunitate unita care are mult mai multa credibilitate si impact daca actioneaza impreuna impotriva problemei. In al 2lea rand faciliteaza trimiterea de sesizari catre institutiile abilitate, acestea fiind la doar un click distanta. Mai nou avem si aplicatie de iOS si urmeaza sa avem si aplicatie de Android.",
    },
    {
        question: "Pot trimite sesizari si independent de aceasta aplicatie?",
        answer: "Sigur, puteti face sesizari pe orice cale legala. Puteti trimite sesizari prin intermediul aplicatiei, sau independent pe adresele institutiilor, sau chiar ii puteti suna daca fapta se desfasoare in timpul orelor lor de munca."
    },
    {
        question: "Cate sesizari pot trimite?",
        answer: "In mod normal autoritatile trebuie instiintate de fiecare data cand faptele se petrec. Avand in vedere modul greoi de operare al institutiilor si faptul ca nu dorim sa facem SPAM, am limitat sesizarile la 2 (doua) pe zi, cu conditia ca intre cele 2 sa fie o diferenta de minim o ora"
    },
    {
        question: "Care este corpul sesizarii?",
        answer: "Corpul sesizarii (sesizarea insasi) cuprinde strict elemente care privesc direct autoritatea carora li se adreseaza. Textele au fost redactate impreuna cu avocatii care sprijina demersul nostru."
    },
    {
        question: "Toate sesizarile de pe harta sunt reale?",
        answer: "Evident, toate sesizarile sunt reale si sunt trimise de oameni, de vecinii vostri. Toate sesizari contin datele pe care le-ati furnizat, cat si IP-ul de unde se face sesizarea. Suntem 100% transparent cu autoritatile statului, vrem sa raportam situatii reale, sa ne aparam, fara a incurca inutil activitatile acestora prin sesizari nefondate"
    },
    {
        question: "Datele mele sunt in siguranta?",
        answer: "Pentru siguranta datelor Dvs acestea sunt stocate dupa ce au fost criptate."
    },
    {
        question: "Imi pot sterge datele?",
        answer: "Daca din orice motive doresti stergerea datelor, te rugam sa ne trimiti un email de pe adresa cu care ti-ai facut contul si sa ne soliciti acest lucru."
    },
    {
        question: "Sunt plasat cu exactitate pe harta cu ultimele rapoarte?",
        answer: "Nu, datele de localizare sunt aproximative si reprezinta puncte fixe din aproprierea adresei tale."
    },
    {
        question: "Rezolva aplicatia problemele?",
        answer: "Din pacate nu, aplicatia nu va rezolva singura problema, dar isi atinge cu brio obiectivele mentionate anterior"
    }
];


export default function Qa()
{
    return <>
        <Box boxShadow="md" bg="gray.100" rounded={4} p={4}>
            <Accordion>
                {qaItems.map((item) => (
                    <AccordionItem>
                        <h2>
                            <AccordionButton>
                                <Box as="span" flex='1' textAlign='left'>
                                    <strong>{item.question}</strong>
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            {item.answer}
                        </AccordionPanel>
                    </AccordionItem>
                ))}
            </Accordion>
        </Box>
    </>;
}