import {createResource, Entity, RestEndpoint} from '@rest-hooks/rest';
import configs from '../config/config';
import {County} from "./County";
import {Organization} from "./Organization";
import {Sensor} from "./Sensor";

export class City extends Entity {
    id = 0;
    code = '';
    name = '';
    latitude = 0;
    longitude = 0;
    Organizations: Organization[] = [];
    Sensors: Sensor[] = [];

    pk() {
        return `${this.id}`;
    }

    static schema = {
        Organizations: [Organization],
        Sensors: [Sensor],
    };
}

City.schema = {
    ...City.schema,
    Organizations: [Organization],
    Sensors: [Sensor],
}

// @ts-ignore
export const getCities = new RestEndpoint({
    urlPrefix: configs.apiUrl,
    path: '/county/:id',
    method: 'GET',
    schema: City,
});
