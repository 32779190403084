import {createResource, Entity, RestEndpoint} from '@rest-hooks/rest';
import {Report} from "./Report";
import {City} from "./City";
import configs from "../config/config";
import {County} from "./County";

export class User extends Entity {
    id = 0;
    firstName = '';
    lastName = '';
    email = '';
    full_name = '';
    address = '';
    phone = '';
    state = 0;
    street = '';
    street_no = '';
    avatar = '';
    access_token = '';
    CityId = 0;
    CountyId = 0;
    Reports: Report[] = [];
    City = City.fromJS();
    County = County.fromJS();
    canSend = {
        value: false,
        now: 0,
        max: 0,
        delay: 0,
        care: false,
    };
    pk() {
        return `${this.id}`;
    }

    static schema = {
        City: City,
        County: County,
    };
}

// @ts-ignore
export const UserResource = new createResource({
    urlPrefix: configs.apiUrl,
    path: '/user/:id',
    schema: User,
});

export const checkUserToken = new RestEndpoint({
    urlPrefix: configs.apiUrl,
    path: '/user/check',
    method: 'POST',
    schema: User,
});

export const loginWithGoogle = new RestEndpoint({
    urlPrefix: configs.apiUrl,
    path: '/user/google',
    method: 'POST',
    schema: User,
});

export const loginWithFacebook = new RestEndpoint({
    urlPrefix: configs.apiUrl,
    path: '/user/facebook',
    method: 'POST',
    schema: User,
});

export const loginWithEmail = new RestEndpoint({
    urlPrefix: configs.apiUrl,
    path: '/user/email',
    method: 'POST',
});