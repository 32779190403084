import {useSuspense} from "@rest-hooks/react";
import {OrganizationResource} from "../api/Organization";
import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  Heading,
  Stack,
  Text,
  Image,
  Divider,
  Stat,
  StatLabel, StatNumber, StatHelpText, StatArrow, Alert, AlertIcon
} from "@chakra-ui/react";
import React from "react";
import Logo from "../assets/logo_.png";
import {Link} from "react-router-dom";
import _ from "lodash";

export const Organizations = () => {
  const organizations = useSuspense(OrganizationResource.getList);

  return <Box boxShadow="md" bg="gray.100" rounded={4} p={4}>
    {organizations.map(organization => (
        <Card
            direction={{ base: 'column', sm: 'row' }}
            overflow='hidden'
            variant='outline'
        >
          <Image
              objectFit='cover'
              maxW={{ base: '100%', sm: '200px' }}
              src={Logo}
              p={4}
          />

          <Stack>
            <CardBody>
              <Heading size='md'>{organization.name}</Heading>
              <Text py='2'>
                Localitati implicate: {_.join(_.map(organization.Cities, 'name'), ', ')}
              </Text>
              <Text>
                {organization.description}
              </Text>
              <Divider />
              <Stat>
                <StatLabel>Membrii</StatLabel>
                <StatNumber>{organization.members} / {organization.members_target}</StatNumber>
              </Stat>
            </CardBody>

            <CardFooter flexDirection="column" gap={4}>
              <Button variant='solid' colorScheme='blue'>
                <Link to={`/organizatii/${organization.id}`}>
                  Implica-te!
                </Link>
              </Button>
              {!organization.iban && (
                  <Box>
                    <Alert status="warning"><AlertIcon />Inscrierea in asociatie este momentan indisponibila</Alert>
                  </Box>
              )}
            </CardFooter>
          </Stack>

        </Card>
    ))}
  </Box>
}
