import {useDLE, useSuspense} from "@rest-hooks/react";
import {checkMember, OrganizationResource, subscription} from "../api/Organization";
import {
    Alert,
    Box,
    Divider, Flex, Grid,
    Heading,
    Skeleton,
    SkeletonCircle,
    SkeletonText,
    Stack,
    Stat,
    StatLabel,
    StatNumber,
    Text
} from "@chakra-ui/react";
import {useParams} from "react-router";
import React, {useEffect, useState} from "react";
import {Join} from "../components/Join";
import userDetails from "../UserDetails";
import {useUser} from "../utils/hooks/UserProvider";
import {Sensor, sensorData} from "../api/Sensor";
import {perc2color} from "../utils/general";
import _ from "lodash";
import config from "../config/config";

type QueryParams = {
    id: string | number;
}
export const OrganizationDetails = () => {
    const {id} = useParams<{id: string | undefined}>();
    // @ts-ignore
    const {data: organization, loading, error} = useDLE(OrganizationResource.get, {id});
    const {isAuthenticated, userData} = useUser();
    const [sensors, setSensors] = useState<Sensor[]>([]);

    const [isMember, setIsMember] = useState<boolean>(false);

    useEffect(() => {
        if (!loading && isAuthenticated && userData && organization) {
            checkMember({id: organization.id, token: userData?.access_token}).then(() => setIsMember(true)).catch(() => setIsMember(false));
            // subscription({id: organization.id, token: userData?.access_token}).then(() => {}).catch(() => {});
        }
    }, [loading, organization, userData, isAuthenticated]);

    // @ts-ignore
    // @ts-ignore
    return <>
        {loading ? (
            <Box boxShadow="md" bg="gray.100" rounded={4} p={4}>
                <SkeletonCircle size='10' />
                <SkeletonText mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
            </Box>
        ) : error ? (
            <Box boxShadow="md" bg="gray.100" rounded={4} p={4}>
                <Alert status="error">Pagina inexistenta</Alert>
            </Box>
        ) : organization && (
            <>
                <Flex gap={4} flexDirection={{ base: 'column', md: 'row' }}>
                    <Box w={{ base: 'full', md: 3/4}}>
                        <Heading size='xl'>{organization.name}</Heading>
                        <Box boxShadow="md" bg="gray.100" rounded={4} p={4}>
                            <Text>{organization.description}</Text>
                            <Text display="flex" flexDirection="column">
                                <span>Denumire: {organization.name}</span>
                                <span>Adresa: {organization.address}</span>
                                <span>CUI: {organization.phone}</span>
                                <span>IBAN: {organization.iban}</span>
                                <span>Email: {organization.email}</span>
                                <span>Website: <a href={organization.website} target="_blank">{organization.website}</a></span>
                                <span>Facebook: <a href={organization.facebook} target="_blank">{organization.facebook}</a></span>
                            </Text>
                            <Text py='2'>
                                Localitati implicate: {_.join(_.map(organization.Cities, 'name'), ', ')}
                            </Text>
                            <Divider/>
                            <Stat>
                                <StatLabel>Membrii</StatLabel>
                                <StatNumber>{organization.members} / {organization.members_target}</StatNumber>
                                <Text fontSize="xs">*Numarul se actualizeaza automat dupa fiecare inscriere
                                    finalizata</Text>
                            </Stat>
                            <Divider />
                            <Heading size="md">Calitatea aerului in localitatile vizate</Heading>
                            <Flex flexWrap="wrap">
                                {sensors.length > 0 ? sensors.map(((sensor, index) => (
                                    <Flex gap={4} w={1/4}>
                                        Senzor #{index + 1}
                                        <Box border={`1px solid ${perc2color(sensor.perc + 5)}`} boxShadow='md' style={{ background: perc2color(sensor.perc), width: "16px", height: "16px", borderRadius: "50%" }}></Box>
                                    </Flex>
                                ))) : (
                                    <Text>Se incarca datele</Text>
                                )}
                            </Flex>
                            <Divider/>
                            <Heading size="md">Obiective</Heading>
                            <Box p={4}>
                                <div dangerouslySetInnerHTML={{__html: organization.objectives}}/>
                            </Box>
                            <Alert status="warning">Prin plata cotizatiei admiti faptul ca ai citit Termenii si conditiile de prelucrare a datelor cu caracter personal si esti de acord cu acestia. In caz contrar te rugam sa ne contactezi la adresa {config.contact}</Alert>
                        </Box>
                    </Box>
                    <Flex gap={4} flexDirection="column" w={{base: 'full', md: 1 / 3}}>
                        {organization.stripeButton && (
                            <Box display="flex" flexDirection="column" boxShadow="md"
                                 bg="gray.100" gap={4} rounded={4} p={4}>
                                <Heading size="md">Devino membru!</Heading>
                                {!isMember ? (
                                    <stripe-buy-button
                                        buy-button-id={organization.stripeButton}
                                        publishable-key={config.stripeSecret}
                                    >
                                    </stripe-buy-button>
                                ) : (
                                    <Alert status="success">Esti deja membru al asociatiei. Multumim!</Alert>
                                )}
                                {!isAuthenticated && (
                                    <Alert status="info" fontSize="xs" rounded={4}>
                                        Pentru a vedea daca esti sau nu membrul acestei organizatii trebuie sa fii autentificat. Intoarce-te la prima pagina si conecteaza-te la aplicatie.
                                    </Alert>
                                )}
                                <Alert status="info" fontSize="xs" rounded={4}>
                                    Prin plata cotizatiei devi membru sustinator, ajuti asociatia pentru a-si indeplini
                                    obiectivele si poti participa la Adunarile Generale pe care aceasta le organizeaza.
                                </Alert>
                            </Box>
                        )}
                        {organization.stripeButtonDonation && (
                            <Box display="flex" flexDirection="column" boxShadow="md"
                                 bg="gray.100" gap={4} rounded={4} p={4}>
                                <Heading size="md">Doneaza!</Heading>
                                <stripe-buy-button
                                    buy-button-id={organization.stripeButtonDonation}
                                    publishable-key={config.stripeSecret}
                                >
                                </stripe-buy-button>
                                <Alert status="info" fontSize="xs" rounded={4}>
                                    Daca nu vrei sa ai nicio legatura cu ONG-ul, dar totusi vrei sa sustii demersurile acestuia, atunci poti dona oricand si oricat.
                                </Alert>
                            </Box>
                        )}
                        <Box display="flex" flexDirection="column" boxShadow="md"
                             bg="gray.100" gap={4} rounded={4} p={4}>
                            <Heading size="md">Doneaza direct in cont!</Heading>
                            <Text>Pentru a evita comisioanele percepute la plata online poti transfera suma dorita direct in contul asociatiei.</Text>
                            <Text>IBAN: {organization.iban}</Text>
                            <Text>Titular: {organization.name}</Text>
                            <Text>Moneda: RON</Text>
                        </Box>
                    </Flex>
                </Flex>

            </>
        )}
    </>
}
