import {Entity, RestEndpoint} from "@rest-hooks/rest";
import configs from "../config/config";
import {User} from "./User";
import {Report} from "./Report";

export class News extends Entity {
    id = 0;
    body = '';
    type = null;
    news_type = 0;
    createdAt:string = '';

    pk() {
        return `${this.id}`;
    }

    get status(): "warning" | "info" | "success" | "error" | "loading" | undefined {
        if (this.news_type === 1) return 'info';
        if (this.news_type === 2) return 'error';
        return 'warning';
    }
}

export const newsList = new RestEndpoint({
    urlPrefix: configs.apiUrl,
    path: '/news',
    method: 'POST',
    schema: News,
});