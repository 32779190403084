import {User, UserResource} from "./api/User";
import {Field, FieldProps, Form, Formik, FormikContextType, FormikProps, useFormik, useFormikContext} from "formik";
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormHelperText,
    FormLabel,
    Input,
    Select,
    SimpleGrid, useToast,
    VStack
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {useUser} from "./utils/hooks/UserProvider";
import {City, getCities} from "./api/City";
import counties, {County} from "./config/counties";
import {Dropdown, FormField} from "semantic-ui-react";
import {formatCities, formatCounties} from "./utils/general";
import {MyDD} from "./components/DD";

type Props = {
    userData: User;
}

const UserDetails = ({userData}: Props) => {
    const [cities, setCities] = useState<City[]>();

    const formik: FormikContextType<User> = useFormikContext();

    useEffect(() => {
        if (formik.values.CountyId) {
            getCities({id: formik.values.CountyId})
                .then(r => setCities(r.Cities));
        }
    }, [formik.values.CountyId]);

    return (
        <Box bg="white" p={6} rounded="md">
            <Form>
                <SimpleGrid columns={2} spacing={5} mb={5}>
                    <FormControl isRequired>
                        <FormLabel>Email address</FormLabel>
                        <Input type='email' id="email" name="email" isDisabled value={formik.values.email}
                               variant="filled" onChange={() => {
                        }}/>
                        <FormHelperText>Adresa de email.</FormHelperText>
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel>Nume complet</FormLabel>
                        <Input type='text' id="full_name" name="full_name" value={formik.values.full_name}
                               variant="filled" onChange={formik.handleChange}/>
                        <FormHelperText>Nume si prenume</FormHelperText>
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel>Telefon</FormLabel>
                        <Input type='text' name="phone" id="phone" value={formik.values.phone} variant="filled"
                               onChange={formik.handleChange}/>
                        <FormHelperText>De forma: 07** *** ***</FormHelperText>
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel>Judet</FormLabel>
                        <MyDD name="CountyId" options={formatCounties(counties)} />
                        <FormHelperText>Cauta sau scrie primele litere</FormHelperText>
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel>Localitate</FormLabel>
                        <MyDD name="CityId" options={formatCities(cities)} />
                        <FormHelperText>We'll never share your email.</FormHelperText>
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel>Strada</FormLabel>
                        <Input type='text' name="street" id="street" value={formik.values.street} variant="filled"
                               onChange={formik.handleChange}/>
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel>Numar</FormLabel>
                        <Input type='text' name="street_no" id="street_no" value={formik.values.street_no}
                               variant="filled" onChange={formik.handleChange}/>
                    </FormControl>
                </SimpleGrid>
                <Button type="submit" colorScheme="purple" width="full">
                    Salveaza detaliile
                </Button>
            </Form>
        </Box>
    );
}

export default UserDetails;