import {createContext, Dispatch, SetStateAction, useCallback, useContext, useEffect, useState} from "react";
import {getNextPage, getReports, Report, ReportResource} from "../../api/Report";
import {fetchCoordinates} from "../fetchCoordinates";
import {User} from "../../api/User";
import {useUser} from "../hooks/UserProvider";
import {useToast} from "@chakra-ui/react";
import {HookResource} from "@rest-hooks/rest";

type ReportType = {
    lat: string;
    long: string;
    date: Date;
}

type ReportContextType = {
    lastReports: Report[],
    sendReport: () => void,
    setLastReportsInterval: Dispatch<SetStateAction<number>>,
    lastReportsInterval: number,
    counter: number,
    isLoading: boolean,
    setCounter: (value: (counter: any) => any) => void,
}

export const ReportContext = createContext({} as ReportContextType);

type ReportProviderProps = {
    children: JSX.Element;
    resource: any,
    isCare: boolean
}

export const useReport = () => useContext(ReportContext);

const ReportProvider = ({children, resource, isCare}: ReportProviderProps) => {
    const [lastReports, setLastReports] = useState<Report[]>([]);
    const [counter, setCounter] = useState<number>(1);
    const [lastReportsInterval, setLastReportsInterval] = useState<number>(24);
    const {userData, setUserData, coordinates} = useUser();
    const toast = useToast();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        const reports = resource.getList({limit: lastReportsInterval}).then((data: Report[]) => setLastReports(data));
        if (reports) {
            setLastReports(reports);
        }
    }, [userData, lastReportsInterval]);

    const sendReport = useCallback(async () => {
        if (typeof userData === 'undefined') {
            toast({
                title: 'Probleme!',
                description: "Probabil ai atins numarul maxim de sesizari pe care le poti trimite trimite intr-o zi. Daca problema persista, te rugam sa ne scri.",
                status: 'error',
                duration: 9000,
                isClosable: true,
            });

            return false;
        }
        setIsLoading(true);
        resource.create({
            lat: coordinates.lat,
            long: coordinates.long,
            token: userData.access_token
        })
            .then((user: User) => setUserData(user)).then(() => {
            resource.getList().then((data: Report[]) => setLastReports(data));

            return true;
        }).then(() => {
            toast({
                title: 'Sesizare trimisa',
                description: "Ai trimis o noua sesizare catre organele abilitate.",
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
        }).catch(() => {
            setIsLoading(false);
            toast({
                title: 'Probleme!',
                description: "Probabil ai atins numarul maxim de sesizari pe care le poti trimite trimite intr-o zi. Daca problema persista, te rugam sa ne scri.",
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        });
    }, [userData, coordinates]);

    return <ReportContext.Provider value={{lastReports, sendReport, lastReportsInterval, setLastReportsInterval, counter, setCounter, isLoading}}>
        {children}
    </ReportContext.Provider>
}

export default ReportProvider;