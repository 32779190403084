import React, {createContext, createRef, ReactNode, useCallback, useContext, useEffect, useRef, useState} from "react";
import {checkUserToken, loginWithEmail, User, UserResource} from "../../api/User";
import {useLocalStorage} from "./useLocalStorage";
import {completeUserDetails, noCityCountyData} from "../general";
import config from "./../../config/config";
import moment from "moment";
import {
    Alert,
    Button, Divider, FormControl,
    FormLabel, Input,
    Modal,
    ModalBody, ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay, Text,
    useDisclosure,
    useToast
} from "@chakra-ui/react";
import {fetchCoordinates} from "../fetchCoordinates";
import queryString from "query-string";

type UserType = {
    name: string | null,
    token: string | null,
    isAuthenticated: boolean,
    setToken: (value: string | null) => void,
    setIsAuthenticated: (value: boolean) => void,
    userData: User | undefined,
    setUserData: (value: User) => void,
    showEditForm: boolean,
    setShowEditForm: (value: boolean) => void,
    sendAvailable: boolean,
    lastReportDate: moment.Moment,
    lastReportHours: number,
    loginToast: () => void,
    activeIndex: number[],
    setActiveIndex: (value: number[]) => void,
    coordinates: {
        lat: string | number,
        long: string | number,
    },
    setIsCare: (value: boolean) => void,
    isCare: boolean,
    openEmailLogin: () => void,
    isComplete: boolean,
};

const defaultUser = {
    name: null,
    token: null,
    isAuthenticated: false,
    setToken: () => {},
    setIsAuthenticated: () => {},
    userData: {} as User,
    setUserData: () => {},
    showEditForm: true,
    setShowEditForm: () => {},
    sendAvailable: true,
    lastReportDate: moment(),
    lastReportHours: 0,
    activeIndex: [0],
    setActiveIndex: () => {},
    coordinates: {
        lat: 0,
        long: 0,
    },
    loginToast: () => {},
    setIsCare: () => {},
    isCare: false,
    openEmailLogin: () => {},
    isComplete: false,
};
const UserContext =
    createContext<UserType>(defaultUser);

type Props = {
    children: ReactNode;
};

export const useUser = () => useContext(UserContext);

export const UserProvider: React.FC<Props> = ({ children }) => {
    const [isCare, setIsCare] = useState<boolean>(false);
    const [name, setName] = useState(defaultUser.name);
    const [token, setToken] = useLocalStorage<string | null>("token", null);
    const [isAuthenticated, setIsAuthenticated] = useState(defaultUser.isAuthenticated);
    const [userData, setUserData] = useState<User>();
    const [showEditForm, setShowEditForm] = useState(!completeUserDetails(userData));
    const [lastReportDate, setLastReportDate] = useState<moment.Moment>(defaultUser.lastReportDate);
    const [lastReportHours, setLastReportHours] = useState<number>(defaultUser.lastReportHours);
    const [sendAvailable, setSendAvailable] = useState<boolean>(defaultUser.sendAvailable);
    const [coordinates, setCoordinates] = useState<{lat: string | number, long: string | number}>({lat: 0, long: 0});
    const [activeIndex, setActiveIndex] = useState<number[]>([0]);
    const {isOpen: isEmailLoginOpen, onOpen: openEmailLogin, onClose: closeEmailLogin} = useDisclosure();
    const {isOpen: isIncompelteDataOpen, onOpen: openIncompleteData, onClose: closeIncompleteData} = useDisclosure();
    const [hideIncomplete, setHideIncomplete] = useLocalStorage<boolean>("hideIncomplete", false);
    const [isOtpSending, setIsOtpSending] = useState<boolean>(false);
    const [isComplete, setIsComplete] = useState<boolean>(false);

    const emailRef = createRef<HTMLInputElement>();

    const toast = useToast();
    const { token: qtoken } = queryString.parse(window.location.search);

    const loginToast = useCallback(() => {
        toast({
            title: 'Felicitari!',
            description: "Te-ai autentificat cu succes.",
            status: 'success',
            duration: 9000,
            isClosable: true,
        })
    }, [0])

    useEffect(() => {
        setShowEditForm(!completeUserDetails(userData));

        if (completeUserDetails(userData)) {
            fetchCoordinates(userData)
                .then(async res => {
                    setCoordinates({
                        lat: res[0]?.lat,
                        long: res[0]?.lon,
                    });
                })

            setActiveIndex([0, 2]);
        }

        if (userData && userData.Reports) {
            const lastReport = userData.Reports.at(-1);
            if (typeof lastReport !== 'undefined') {
                const lastReportDate = moment(lastReport.createdAt);
                setLastReportDate(lastReportDate);

                const now = moment();
                const hours = moment.duration(now.diff(lastReportDate)).asHours();

                setLastReportHours(hours);
            }

        }
        if (userData) {
            setIsComplete(completeUserDetails(userData));
            setSendAvailable(userData.canSend.value);

            if (noCityCountyData(userData) && !hideIncomplete) {
                openIncompleteData();
            }
        }
    }, [userData]);

    useEffect(() => {
        if (!isAuthenticated && token) {
            checkUserToken({ token }).then((data: User) => {
                setIsAuthenticated(true);
                setUserData(data);
            }).catch(() => {
                setIsAuthenticated(false);
                setToken(null);
            });
        }
        if (isAuthenticated && !token) {
            setIsAuthenticated(false);
        }
    }, [token, isAuthenticated]);

    const sendOtp = () => {
        setIsOtpSending(true);
        loginWithEmail({email: emailRef.current?.value, care: isCare})
            .then(() => {
                toast({
                    title: 'Felicitari!',
                    description: "Am trimis pe email-ul tau un link magic cu care te poti conecta rapid.",
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                })

                closeEmailLogin();
            })
            .catch(() => {
                toast({
                    title: 'Ups!',
                    description: "Ceva nu a functionat.",
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            }).finally(() => {
                setIsOtpSending(false);
            })
    }

    useEffect(() => {
        if (qtoken) {
            // @ts-ignore
            setToken(qtoken);
        }
    }, [0]);

    return (
        <UserContext.Provider value={{
            name,
            token,
            isAuthenticated,
            setToken,
            setIsAuthenticated,
            userData,
            setUserData,
            showEditForm,
            setShowEditForm,
            sendAvailable,
            lastReportDate,
            lastReportHours,
            loginToast,
            coordinates,
            activeIndex,
            setActiveIndex,
            setIsCare,
            isCare,
            openEmailLogin,
            isComplete,
        }}>
            {children}
            <Modal onClose={closeEmailLogin} isOpen={isEmailLoginOpen} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Conectare cu adresa de email</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Alert size="xs" mb={4}>Iti trimitem un link magic pe adresa de email si te conectezi rapid, fara parola</Alert>
                        <FormControl>
                            <FormLabel>Email</FormLabel>
                            <Input placeholder='Adresa ta de email' ref={emailRef} />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" onClick={sendOtp} disabled={isOtpSending}>Trimite</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            {!hideIncomplete && (
                <Modal onClose={() => {closeIncompleteData(); setHideIncomplete(true);}} isOpen={isIncompelteDataOpen} isCentered>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Atentie!</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <Alert status="warning" size="md" mb={4}>De curand aplicatia s-a extins la nivel national, iar campurile pentru localitate si judet au fost modificate.<br /><br /> Asadar, va rugam sa completati din nou aceste doua campuri.<br /><br/> Multumim!</Alert>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            )}
        </UserContext.Provider>
    )
}