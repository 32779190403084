import {createResource, Entity, RestEndpoint} from "@rest-hooks/rest";
import configs from "../config/config";
import {City} from "./City";
import {Sensor} from "./Sensor";

export class Organization extends Entity {
    id = 0;
    name = '';
    description= '';
    objectives= '';
    email= '';
    address= '';
    phone= '';
    iban= '';
    identifier= '';
    members= 0;
    members_target= 0;
    facebook= '';
    website= '';
    stripeButton = '';
    stripeButtonDonation = '';
    Cities: City[] = [];

    pk() {
        return `${this.id}`;
    }
}

export const OrganizationResource = createResource({
    urlPrefix: configs.apiUrl,
    path: '/organizations/:id',
    schema: Organization,
});

export const checkMember = new RestEndpoint({
    urlPrefix: configs.apiUrl,
    path: '/organizations/member',
    method: 'POST',
});

export const subscription = new RestEndpoint({
    urlPrefix: configs.apiUrl,
    path: '/organizations/subscription',
    method: 'POST',
});

export const donate = new RestEndpoint({
    urlPrefix: configs.apiUrl,
    path: '/organizations/:id/donate',
    method: 'GET',
});
