import {
    Box,
    Button,
    ButtonGroup,
    chakra, CloseButton,
    Container,
    Flex, Heading,
    HStack,
    IconButton,
    Image, Text,
    useBreakpointValue, useColorModeValue, useDisclosure, VisuallyHidden, VStack
} from "@chakra-ui/react";
import Logo from "../assets/logo_.png";
import React from "react";
import {HamburgerIcon} from "@chakra-ui/icons";
import {Link} from "react-router-dom";
import {useUser} from "../utils/hooks/UserProvider";
import Menu from "./Menu";

const NavBar = ({children, ...props}: any) => {
    const bg = useColorModeValue("white", "gray.800");
    const mobileNav = useDisclosure();
    const {isAuthenticated, userData} = useUser();

    return (
        <React.Fragment>
            <chakra.header
                bg={bg}
                w="full"
                px={{
                    base: 2,
                    sm: 4,
                }}
                py={2}
                mb={6}
                shadow="md"
            >
                <Container maxW="container.lg">
                    <Flex alignItems="center" justifyContent="space-between" mx="auto">
                        <Flex>
                            <chakra.a
                                href="/"
                                title="Choc Home Page"
                                display="flex"
                                alignItems="center"
                            >
                                <Flex alignItems="center" fontWeight="bold" gap="2">
                                    <Image width={35} sizes="md" src={Logo}/> <p>IAR<span
                                    style={{color: "#936d00"}}>MIROASE</span></p>
                                </Flex>
                            </chakra.a>
                        </Flex>
                        <HStack zIndex={5} display="flex" alignItems="center" spacing={1}>
                            <HStack
                                spacing={1}
                                mr={1}
                                color="brand.500"
                                display={{
                                    base: "none",
                                    lg: "inline-flex",
                                }}
                            >
                                <Menu />
                            </HStack>
                            <Text>v.1.6.0</Text>
                            <Box
                                display={{
                                    base: "inline-flex",
                                    lg: "none",
                                }}
                            >
                                <IconButton
                                    display={{
                                        base: "flex",
                                        lg: "none",
                                    }}
                                    aria-label="Open menu"
                                    fontSize="20px"
                                    color="gray.800"
                                    _dark={{
                                        color: "inherit",
                                    }}
                                    variant="ghost"
                                    icon={<HamburgerIcon/>}
                                    onClick={mobileNav.onOpen}
                                />

                                <VStack
                                    pos="absolute"
                                    top={0}
                                    left={0}
                                    right={0}
                                    display={mobileNav.isOpen ? "flex" : "none"}
                                    flexDirection="column"
                                    p={2}
                                    pb={4}
                                    m={2}
                                    bg={bg}
                                    spacing={3}
                                    rounded="sm"
                                    shadow="sm"
                                >
                                    <CloseButton
                                        aria-label="Close menu"
                                        onClick={mobileNav.onClose}
                                    />
                                    <Menu />
                                </VStack>
                            </Box>
                        </HStack>
                    </Flex>
                </Container>
            </chakra.header>
        </React.Fragment>
    );
}

export default NavBar;
