import React, {useEffect, useState, useRef} from 'react';
import {AdvancedMarker, useMap, Marker as MarkerI} from "@vis.gl/react-google-maps";
import {Marker, MarkerClusterer} from "@googlemaps/markerclusterer";
import {Point} from "../utils/map";
import {useReport} from "../utils/contexts/ReportContext";

type Props = {points: Point[]};
export const Markers = ({points}: Props) => {
    const {lastReportsInterval} = useReport();
    const map = useMap();
    const [markers, setMarkers] = useState<{[key: string]: Marker}>({});
    const clusterer = useRef<MarkerClusterer | null>(null);

    // Initialize MarkerClusterer
    useEffect(() => {
        if (!map) return;
        if (!clusterer.current) {
            clusterer.current = new MarkerClusterer({map});
        }
    }, [map]);

    // Update markers
    useEffect(() => {
        clusterer.current?.clearMarkers();
        clusterer.current?.addMarkers(Object.values(markers));
    }, [markers, lastReportsInterval]);

    const setMarkerRef = (marker: Marker | null, key: number) => {
        if (marker && markers[key]) return;
        if (!marker && !markers[key]) return;

        setMarkers(prev => {
            if (marker) {
                return {...prev, [key]: marker};
            } else {
                const newMarkers = {...prev};
                delete newMarkers[key];
                return newMarkers;
            }
        });
    };

    return (
        <>
            {points.map(point => (
                <MarkerI
                    position={point}
                    key={point.key}
                    ref={marker => setMarkerRef(marker, point.key)} />
            ))}
        </>
    );
};