import {User} from "../api/User";

type LocationIqReposnseType = {
    lat: string;
    lon: string;
}

export function fetchCoordinates<TResponse>(
    userData: User | undefined,
    // `RequestInit` is a type for configuring
    // a `fetch` request. By default, an empty object.
    config: RequestInit = {}

    // This function is async, it will return a Promise:
): Promise<LocationIqReposnseType[]> {
    if (typeof userData === 'undefined') {
        return new Promise(() => [{
            lat: 0,
            lon: 0,
        }]);
    }
    // Inside, we call the `fetch` function with
    // a URL and config given:
    return fetch(`https://eu1.locationiq.com/v1/search?key=pk.d5c4bcf80ca0d89a82d9eeb761e30ae4&street=${userData.street}&city=${userData.City?.name}&county=${userData.County.name}&&format=json`, config)
        // When got a response call a `json` method on it
        .then((response) => response.json())
        // and return the result data.
        .then((data) => data as LocationIqReposnseType[]);
}