import {User} from "../api/User";
import _ from "lodash";
import moment from "moment-timezone";
import {City} from "../api/City";
import {County} from "../config/counties";

export const completeUserDetails = (data: User | undefined) => {
    if (typeof data === 'undefined') return false;
    return !_.isEmpty(data.full_name) && !_.isEmpty(data.phone) && !_.isEmpty(data.City) &&
        !_.isEmpty(data.street) && !_.isEmpty(data.street_no);
}

export const noCityCountyData = (data: User | undefined) => {
    if (typeof data === 'undefined') return false;
    return !_.isEmpty(data.full_name) && !_.isEmpty(data.phone) && _.isEmpty(data.City) && _.isEmpty(data.County) &&
        !_.isEmpty(data.street) && !_.isEmpty(data.street_no);
}

export const convertTimeToLocalTime = (time: moment.MomentInput) =>
{
    const timeTz = moment.utc(time).local();

    return new Intl.DateTimeFormat('ro-RO', { dateStyle: 'full', timeStyle: 'long', timeZone: 'Europe/Bucharest' }).format(timeTz.toDate());
}

export const formatCities = (cities: City[] | undefined) => {
    let formatted: { key: number; value: number; text: string; }[] = [];

    if (!cities) {
        return formatted;
    }

    cities.map(city => {
       formatted.push({
           key: city.id,
           value: city.id,
           text: city.name,
       });
    });

    return formatted;
}

export const formatCounties = (counties: County[]) => {
    let formatted: { key: number; value: number; text: string; }[] = [];

    if (!counties) {
        return formatted;
    }

    counties.map(county => {
        formatted.push({
            key: county.id,
            value: county.id,
            text: county.name,
        });
    });

    return formatted;
}

export const perc2color = (perc: number) => {
    let r = 0;
    let g = 0;
    let b = 0;

    if (perc >= 100) {
        return "#764194";
    }

    perc = 100 - perc;

    if(perc < 50) {
        r = 255;
        g = Math.round(5.1 * perc);
    }
    else {
        g = 255;
        r = Math.round(510 - 5.10 * perc);
    }
    const h = r * 0x10000 + g * 0x100 + b * 0x1;
    return '#' + ('000000' + h.toString(16)).slice(-6);
}