import {
    AbsoluteCenter,
    Alert, AlertIcon, Box,
    Button, Checkbox, Divider, Flex, Heading, Modal, ModalContent, ModalHeader, ModalOverlay,
    Text, Tooltip, useDisclosure
} from "@chakra-ui/react";
import React, {useCallback, useEffect, useState} from "react";
import {useReport} from "./utils/contexts/ReportContext";
import {useUser} from "./utils/hooks/UserProvider";
import config from "./config/config";
import {getPreview} from "./api/Report";
import {completeUserDetails} from "./utils/general";
import Mailto from "react-mailto.js";
import {EmailIcon, QuestionIcon} from "@chakra-ui/icons";
import {Link} from "react-router-dom";

const ReportComponent = () => {

    const {sendReport, isLoading} = useReport();
    const {sendAvailable, token, coordinates, userData, isComplete} = useUser();
    const {isOpen: isDspPreviewOpen, onOpen: openDspPreview, onClose: closeDspPreview} = useDisclosure();
    const {isOpen: isGnmPreviewOpen, onOpen: openGnmPreview, onClose: closeGnmPreview} = useDisclosure();
    const [previewDsp, setPreviewDsp] = useState('');
    const [previewGnm, setPreviewGnm] = useState('');

    const [ accept, toggleAccept ] = useState<boolean>(false);

    useEffect(() => {
        getPreview({
            lat: coordinates.lat,
            long: coordinates.long,
            token: token,
            where: "dsp"
        }).then(data => {
            setPreviewDsp(data);

        }).catch(() => {
        });
        getPreview({
            lat: coordinates.lat,
            long: coordinates.long,
            token: token,
            where: "gnm"
        }).then(data => {
            setPreviewGnm(data);
        }).catch(() => {
        });
    }, [coordinates.lat, coordinates.long, token]);

    return (
        <>
            {!sendAvailable ? (
                <>
                <Alert mb={4} status={'error'}>
                    <Flex flexDirection="column">
                    Exista un ONG in zona ta care lupta pentru a rezolva problemele cu care tu te confrunti. Iti recomandam sa te interesezi despre el si, daca te-au convins, sa sustii demersurile acestora
                    {userData && userData.City.Organizations.map(org => <Link to={`/organizatii/${org.id}`}><Button colorScheme="blue">{org.name}</Button></Link>)}
                    </Flex>
                </Alert>
                <Alert mb={4} status='warning'>
                    <AlertIcon/>
                    Poti trimite {userData?.canSend.max} sesizari intr-o zi la o diferenta de minim {userData?.canSend.delay} ore intre ele.
                </Alert>
                </>
            ) : (
                <>
                    <Text mb={4} fontSize="sm">Apasa butonul de mai jos pentru a trimite sesizarea ta catre toate organele
                        abilitate. Mailul tau va ajunge la urmatoarele adrese: {userData?.County.Authorities.map((authority) =>
                            (<><strong>{authority.email}</strong> - {authority.name}, </>))}</Text>
                    <Text mb={4} fontSize="sm">Vezi cum o
                        sa arate sesizarea ta catre <span style={{textDecoration: "underline", cursor: "pointer"}}
                                                          onClick={openDspPreview}>DSP</span> si cea catre <span
                            onClick={openGnmPreview} style={{textDecoration: "underline", cursor: "pointer"}}>GNM</span></Text>
                    <Checkbox isInvalid={!accept} checked={accept} onChange={() => toggleAccept(!accept)}><Text fontSize="xs">Sunt constient de faptul ca sesizarea este trimisa in numele meu, am citit continutul si sunt de acord cu trimiterea acesteia.</Text></Checkbox>
                    <Button isDisabled={!accept || isLoading} isLoading={isLoading} width="full" colorScheme="red" onClick={sendReport}>Trimite o
                        sesizare</Button>
                </>
            )}
            {isComplete && (
                <>
                    <Box position='relative' padding='10'>
                        <Divider />
                        <AbsoluteCenter bg='gray.50' px='4'>
                            SAU
                        </AbsoluteCenter>
                    </Box>
                    <Heading m={0} size="xs">
                        Acum poti trimite sesizari in nume propriu. Apasand butoanele de mai jos se va deschide casuta de email cu textul, subiectul si adresele precompletate
                        <Tooltip hasArrow label='Daca nu se intampla nimic la apasarea butoanelor inseamna ca nu aveti setata o casuta de email. In acest caz puteti sa folositi trimiterea standard' bg='gray.300' color='black'>
                            <QuestionIcon />
                        </Tooltip>
                    </Heading>
                    <Flex gap={2} flexDirection="column">
                    {userData?.County.Authorities.map((authority) => (
                        <Mailto
                            secure={true}
                            to={[authority.email]}
                            subject="Miros insuportabil la adresa unde locuiesc"
                            body={authority.type == 2 ? previewDsp : previewGnm}
                        >
                            <Button leftIcon={<EmailIcon />} colorScheme='teal' variant='solid'>
                                {authority.name}
                            </Button>
                        </Mailto>
                    ))}
                    </Flex>
                </>
            )}
            <Modal isOpen={isDspPreviewOpen} size="3xl" onClose={closeDspPreview}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>Sesizarea ta</ModalHeader>
                    <Box p={6} className="preview">
                        {!!previewDsp && <div dangerouslySetInnerHTML={{__html: previewDsp.replaceAll('\n', '<br />')}}/>}
                    </Box>
                </ModalContent>
            </Modal>
            <Modal isOpen={isGnmPreviewOpen} size="3xl" onClose={closeGnmPreview}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>Sesizarea ta</ModalHeader>
                    <Box p={6} className="preview">
                        {!!previewGnm && <div dangerouslySetInnerHTML={{__html: previewGnm.replaceAll('\n', '<br />')}}/>}
                    </Box>
                </ModalContent>
            </Modal>
        </>
    )
}

export default ReportComponent;
