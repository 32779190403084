import {Dropdown, DropdownItemProps} from "semantic-ui-react";
import {formatCounties} from "../utils/general";
import counties from "../config/counties";
import React from "react";
import {FieldHookConfig, useField} from "formik";
import {Select} from "semantic-ui-react";

export const MyDD = (props: { name: string | FieldHookConfig<any>; options: DropdownItemProps[]; }) => {
    const [field, meta, helpers] = useField(props.name);
    return (
        <Select
            placeholder='Selecteaza'
            fluid
            search
            selection
            options={props.options}
            value={field.value}
            onChange={(e, {value}) => helpers.setValue(value)}
        />
    );
};