import React, {useEffect, useState} from 'react';
import './App.css';
import {ReportResource} from "./api/Report";

import ReportProvider, {useReport} from "./utils/contexts/ReportContext";
import Home from "./pages/Home";
import { Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import About from "./pages/About";
import Reports from "./pages/Reports";
import Qa from "./pages/Qa";
import Cookies from "./pages/Cookies";
import Policy from "./pages/Policy";
import Stats from "./pages/Stats";
import {Organizations} from "./pages/Organizations";
import {OrganizationDetails} from "./pages/OrganizationDetails";

function App() {
    return (
        <Routes>
            <Route path="/" element={<Layout />}>
                <Route index element={<ReportProvider resource={ReportResource} isCare={false}><Home /></ReportProvider>} />
                <Route path="despre" element={<About />} />
                <Route path="organizatii/:id" element={<OrganizationDetails />} />
                <Route path="organizatii" element={<Organizations />} />
                <Route path="intrebari" element={<Qa />} />
                <Route path="sesizari" element={<Reports />} />
                <Route path="cookies" element={<Cookies />} />
                <Route path="confidentialitate" element={<Policy />} />
                <Route path="statistici" element={<Stats />} />
            </Route>
        </Routes>
    )
}

export default App;
