import {createResource, Entity, RestEndpoint} from '@rest-hooks/rest';
import configs from '../config/config';
import {Authority} from "./Authority";

export class County extends Entity {
    id = 0;
    code = '';
    name = '';
    Authorities: Authority[] = [];

    pk() {
        return `${this.id}`;
    }

    static schema = {
        authorities: [Authority],
    };
}

// @ts-ignore
export const CountyResource = new createResource({
    urlPrefix: configs.apiUrl,
    path: '/county/:id',
    schema: County,
});