const configs = {
    apiUrl: "https://www.iarmiroase.ro/api",
    contact: "contact@iarmiroase.ro",
    google: {
        oauth: {
            clientId: '261678008472-mbss24f5a9k5fgt3bv59ltvv36mtkrq7.apps.googleusercontent.com',
            secret: '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',
        },
        mapsApiKey: 'AIzaSyCzURSgxFPEPjwaETLTj1_nLTWsnTVICMU',
    },
    general: {
        reportDelayHours: 24,
        testMode: false,
    },
    stripeSecret: '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',
};

const dev = {
    general: {
        reportDelayHours: 0,
        testMode: true
    },
    apiUrl: "https://staging.iarmiroase.ro/api"
};

const local = {
    general: {
        reportDelayHours: 0,
        testMode: true
    },
    apiUrl: "http://localhost:3001/api",
    stripeSecret: 'sk_test_51OgtmfHIG4wFk8GOYCt3b8LSaqkzcqG4v76Bc3aekSZZJQI3V8m3nMIHzvmTRNnPBuvrDxZPVYsjK6QJT0yKxuqt00UE5eTQ9M',
};

const prod = {};
console.log(process.env);
let envConfig;
if (process.env.REACT_APP_MODE === "development") {
    envConfig = dev;
} else if (process.env.REACT_APP_MODE === "local") {
    envConfig = local;
} else {
    envConfig = prod;
}

const newConf = {...configs, ...envConfig}

export default newConf;
