import {Report} from "../api/Report";

export type Point = google.maps.LatLngLiteral & {key: number};

export const formattedReports = (reports: Report[]): Point[] => reports.map(report => ({
    key: report.id,
    name: report.rid,
    lat: parseFloat(String(report.lat)),
    lng: parseFloat(String(report.long)),
}));

